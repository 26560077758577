import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleChefSignUp } from "../../auth/Auth";
import { useDispatch, useSelector } from "react-redux";
import { signOutUser } from "../../../store/slice/user";
import Modal from "react-modal";
import InputMask from "react-input-mask";

const TopBanner = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    // zip_code: "",
    phone: "",
    profile_pic: "",
    cover_pic: "",
    is_chef: 1,
    longitude: "",
    latitude: "",
  });
  // For user to see formatted phone number
  const [formattedPhone, setFormattedPhone] = useState("");

  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  // for user geolcation error handleing
  const [locationError, setLocationError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("latitude ", latitude, " Longitude ", longitude);
          setLocationError(null);
          setIsLoading(false);
          setIsModalOpen(false); // Close the modal if it was open
          setFormData({
            ...formData,
            latitude: latitude,
            longitude: longitude,
          });
          // setCoordinates({
          //   lat: latitude,
          //   lng: longitude,
          // });
        },
        (error) => {
          setIsLoading(false);
          setIsModalOpen(true); // Open the modal to show the error
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setLocationError(
                "Location access is required to complete the checkout.\n Location access is denied. Please enable it in your browser settings and refresh the page."
              );
              break;
            case error.POSITION_UNAVAILABLE:
              setLocationError("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              setLocationError("The request to get your location timed out.");
              break;
            default:
              setLocationError("An unknown error occurred.");
          }
        }
      );
    } else {
      setLocationError("Geolocation is not supported by your browser.");
      setIsModalOpen(true); // Open the modal to show the error
      setIsLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      if (formData.phone === "") {
        setError("Mobile number must be in the format 03XXXXXXXXX");
      }
    }

    if (name === "phone") {
      const phoneRegex = /^03\d{9}$/;
      if (!phoneRegex.test(value)) {
        setError("Mobile number must be in the format 03XXXXXXXXX");
      } else {
        setError("");
      }
      // Update the credentials with the formatted phone number
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (name === "email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // const handleChange = (e) => {
  //   // setFormData({ ...formData, [e.target.name]: e.target.value });
  //   const { name, value } = e.target;

  //   if (name === "phone") {
  //     // Validate the phone number length (exactly 13 characters)
  //     if (value.length > 13) {
  //       setError("Phone number must be exactly 13 characters long.");
  //       setTimeout(()=>{
  //         setError('')
  //       }, [1500])
  //       return;
  //     }

  //     // Validate the phone number format
  //     const isValidPhone = /^[+]?[\d]*$/.test(value);
  //     if (!isValidPhone) {
  //       setError(
  //         "Phone number can only contain numbers and must start with +."
  //       );
  //       return;
  //     }

  //     if (
  //       (value[0] && value[0] !== "+") ||
  //       (value[1] && value[1] !== "9") ||
  //       (value[2] && value[2] !== "2")
  //     ) {
  //       setError("Phone number must start with +92 ", value[1]);
  //       return;
  //     }

  //     // Clear error if the phone number is valid
  //     setError("");

  //     // Set the formatted phone number
  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //   }
  // };

  // image handling
  const fileInputRef = useRef(null);
  const profileDiv = useRef(null);
  const coverImageRef = useRef(null);
  const [isProfile, setIsProfile] = useState(""); // hold image as a url to review
  //profile preview box
  const handleBoxClick = () => {
    // Trigger click on the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // Handling image upload
  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, profile_pic: file }));
      const reader = new FileReader();
      reader.onload = () => {
        // Set the selected image in state
        setIsProfile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, cover_pic: file }));
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authToken } = useSelector((state) => state.user);

  // const validateEmail = (email) => {
  //   const regex =
  //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov|mil|co|info|io|pk)$/;
  //   return regex.test(email);
  // };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!emailError && !error) {
      if (!formData.longitude || !formData.latitude) {
        toast.error("Location is required");
        return;
      }
      if (!formData.profile_pic) {
        profileDiv.current.focus();
        toast.error("Profile picture is required");
        return;
      }
      if (!formData.cover_pic) {
        coverImageRef.current.focus();
        toast.error("Cover photo is required");
        return;
      }
      setIsLoadingForm(true);
      try {
        e.preventDefault();
        toast.dismiss();
    
        // console.log(formData)
        const res = await handleChefSignUp(formData);

        // console.log("reponse ", response)
        if (authToken) {
          dispatch(signOutUser());
        }
        toast.success(typeof(res.message)==="string"? res.message: res.message.original.message || "Register Successfully ");
        setIsLoadingForm(false);

        // navigate("/login");
      } catch (error) {
        toast.error(error.message);
        console.error(error);
        setIsLoadingForm(false);
      }
    }
  };
  useEffect(() => {
    getGeolocation();
  }, []);
  return (
    <>
      <div className="become_aChef">
        <div className="py-16 lg:px-2 px-4">
          <div className="container mx-auto">
            <form onSubmit={onSubmit}>
              <div className="grid grid-cols-12 md:gap-x-10 gap-x-0">
                <div className="lg:col-span-7 col-span-12 lg:order-1 order-2">
                  <h3 className="text-base font-semibold mb-1 text-white uppercase mt-2">
                    Upload Your Photo
                  </h3>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-2 ">
                    {/* Profile */}
                    <div className="form-group md:col-span-2">
                      <div className="">
                        <div
                          tabIndex="-1"
                          ref={profileDiv}
                          className={`w-[140px] h-[140px] border border-borderClr rounded-lg overflow-hidden relative cursor-pointer ${
                            !formData.profile_pic && "focus:border-primary"
                          } focus:border-2`}
                          onClick={handleBoxClick}
                        >
                          {/* selectedImage */}
                          {isProfile ? (
                            <img
                              src={isProfile}
                              alt="Selected"
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <p className="text-center flex items-center justify-center text-sm text-white h-full">
                              Click to add Profile
                            </p>
                          )}
                        </div>
                        {/* Hidden file input */}
                        <input
                          type="file"
                          name="profile_pic"
                          // required
                          accept="image/*"
                          onChange={handleProfileChange}
                          ref={fileInputRef}
                          style={{ display: "none" }}
                        />
                        {/* <h3 className="text-base font-semibold mb-1 text-white uppercase mt-2">
                          Upload Your Profile
                        </h3> */}
                      </div>
                    </div>
                    {/* Cover */}
                    <div className="flex items-center">
                      <label
                        className="block mb-1 font-semibold text-sm font-medium text-white w-40"
                        htmlFor="file_input"
                      >
                        Upload Cover
                      </label>
                    </div>
                    <div></div>
                    <div>
                      <input
                        ref={coverImageRef}
                        accept="image/*"
                        onChange={handleCoverChange}
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none py-2 focus:bg-gray-100 focus:border-2"
                        id="file_input"
                        type="file"
                      />
                    </div>

                    {/* <br /> */}

                    <div className="form-group self-end">
                      <input
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                        type="text"
                        name="first_name"
                        className=""
                        placeholder="First Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                        type="text"
                        name="last_name"
                        className=""
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        value={formData.email}
                        onChange={handleChange}
                        required
                        type="email"
                        name="email"
                        className=""
                        placeholder="john@example.com"
                      />
                      {/* Error warning */}
                      {emailError && (
                        <div className="bg-[#ff1c1c31] mt-1 text-red-900 py-1 px-1 text-[12px] font-semibold">
                          {emailError}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        value={formData.password}
                        onChange={handleChange}
                        required
                        type="password"
                        name="password"
                        className=""
                        placeholder="Password"
                      />
                    </div>
                    {/* <div className="form-group">
                      <input
                        value={formData.zip_code}
                        onChange={handleChange}
                        required
                        type="text"
                        name="zip_code"
                        className=""
                        placeholder="Zip Code"
                      />
                    </div> */}
                    <div className="form-group">
                      <InputMask
                        mask="03999999999" // Fixed +92, and allow the user to enter 10 digits after it
                        value={formData.phone}
                        onChange={handleChange}
                        className="your-input-class" // Your custom class here
                        placeholder="032xxxxxxxxx"
                        required
                        maskChar={"X"}
                        alwaysShowMask={true}
                      >
                        {(inputProps) => (
                          <input
                            {...inputProps}
                            type="text"
                            name="phone"
                            required
                          />
                        )}
                      </InputMask>
                      {/* Error warning */}
                      {error && (
                        <div className="bg-[#ff1c1c31] mt-1 text-red-900 py-1 px-1 text-[12px] font-semibold">
                          {error}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex mt-3 gap-1">
                    <div className="">
                      <input className="mt-1 mr-2" type="checkbox" required />
                    </div>
                    <div className="w-[93%]">
                      <p className="text-white ">
                        I agree to Shef's&nbsp;
                        <Link to="/terms-of-services " className="!underline">
                          Terms of Service
                        </Link>
                        ,&nbsp; and to receive marketing text messages.
                      </p>
                    </div>
                  </div>
                  <div className="flex mt-3 gap-1">
                    <div className="">
                      <input className="mt-1 mr-2" type="checkbox" required />
                    </div>
                    <div className="w-[93%]">
                      <p className="text-white">
                        I acknowledge and agree that I am an independent
                        business and authorized to earn income in the United
                        States.
                      </p>
                    </div>
                  </div>
                  <div className="my-2">
                    <button
                      disabled={error || emailError || isLoadingForm}
                      className="grid-row-s rounded-md py-2 px-8 text-base font-semibold whitespace-nowrap bg-primary text-white hover:text-green-400 uppercase tracking-wider disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isLoadingForm ? (
                        <div className="flex items-center">
                          <svg
                            className="w-5 h-5 mr-2 animate-spin text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                            ></path>
                          </svg>
                          Loading...
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
                {/* Form End */}

                <div className="lg:col-span-5 col-span-12 my-auto lg:order-2 order-1">
                  <div className="lg:text-start text-center">
                    <h1 className="font-semibold sm:text-5xl text-4xl md:leading-tight leading-snug uppercase text-white tracking-wider tracking-widest">
                      Make money doing what you love
                    </h1>
                    <div className="w-[80px] h-[2px] bg-primary my-6 lg:mx-0 mx-auto"></div>
                    <h3 className="alexBrush text-3xl text-headGray hidden">
                      Start your experience
                    </h3>
                    <div className="w-full">
                      <p className="text-xl text-white lg:mb-4 mb-8 mt-5">
                        We give you the tools to run your own food business, on
                        your terms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {}}
        contentLabel="Error"
        shouldCloseOnOverlayClick={false}
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px] bg-white rounded-lg shadow-xl p-6"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50"
      >
        {/* Modal header with location SVG */}
        <div className="flex items-center justify-start border-b pb-3 mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="rgb(236, 32, 68)" // SVG color
            className="mr-2"
          >
            <path d="M12 2C8.13 2 5 5.13 5 9c0 4.75 7 12 7 12s7-7.25 7-12c0-3.87-3.13-7-7-7zm0 10c-1.66 0-3-1.34-3-3s1.34-3 3-3s3 1.34 3 3s-1.34 3-3 3z"></path>
          </svg>
          <h2 className="text-lg font-semibold text-gray-800 leading-tight">
            Location Access Required
          </h2>
        </div>

        {/* Modal content */}
        <div className="flex flex-col items-center space-y-4">
          <p className="text-gray-600 text-center">{locationError}</p>
        </div>
      </Modal>
    </>
  );
};

export default TopBanner;
