import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

export const PrivacyPolicy = () => {
  const pageBnrImg = {
    position: "relative",
    backgroundImage: `url('./media/frontend/img/about/blog/1920x700/banner-2.jpg')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
  return (
    <>
      <Header />
      <section>
        <div className="pageBanr py-20" style={pageBnrImg}>
          <div className="container mx-auto relative z-[1] text-center">
            <h2 className="font-semibold text-3xl text-white uppercase text-secondary tracking-wider tracking-widest">
              Privacy Policy
            </h2>
            <ul className="inline-block text-white mb-0">
              <li className="inline-block text-lg">
                <Link to="/" className="!text-white hover:!text-primary">
                  Home
                </Link>
              </li>
              <li className="inline-block px-3">/</li>
              <li className="inline-block text-lg font-semibold text-primary">
                {" "}
                Privacy Policy
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="privacyPolicy section-padding">
        <div className="container mx-auto lg:px-2 px-4">
          <div className="lg:w-3/4 w-full mx-auto">
            <div className="mb-6">
              <h1 className="text-2xl font-bold text-primary uppercase mb-4">
                Privacy Policy
              </h1>
              <p className="text-base text-gray-700">
                Effective Date: 01 Jan 2025
              </p>
              <p className="text-base text-gray-700 mt-2">
                Welcome to our website. At Shefs Cloud, we are committed to
                protecting your privacy and ensuring the security of your
                personal information. This Privacy Policy outlines how we
                collect, use, and share your information when you visit or
                interact with our Website.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold text-primary uppercase mb-4">
                1. Information We Collect
              </h2>
              <ul className="space-y-4">
                <li>
                  <h3 className="text-lg font-semibold text-gray-900">
                    a. Personal Information
                  </h3>
                  <ul className="space-y-2">
                    <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                      Name, email address, phone number, delivery address,
                      payment details (if provided).{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="text-lg font-semibold text-gray-900">
                    b. Non-Personal Information
                  </h3>
                  <ul className="space-y-2">
                    <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                      Browser type, IP address, device type, operating system,
                      and browsing activity on our Website.
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="text-lg font-semibold text-gray-900">
                    c. User-Generated Content
                  </h3>
                  <ul className="space-y-2">
                    <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                      Reviews, ratings, or comments submitted on the Website.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold text-primary uppercase mb-4">
                2. How We Use Your Information
              </h2>
              <ul className="space-y-2">
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Process and deliver your food orders.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Process and deliver your food orders.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Provide customer support and resolve disputes.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Improve our Website, products, and services.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Send promotional materials and updates (with your consent).
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Analyze usage trends to enhance user experience.
                </li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold text-primary uppercase mb-4">
                3. Sharing Your Information
              </h2>
              <p className="text-base text-gray-700">
                We do not sell your personal information. However, we may share
                your information with:
              </p>
              <ul className="space-y-2 mt-2">
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Service Providers: Delivery partners, payment gateways, and IT
                  support providers.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Legal Authorities: If required by law or to protect our legal
                  rights.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Business Partners: To offer promotions or joint services (only
                  with your consent).
                </li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold text-primary uppercase mb-4">
                4. Cookies and Tracking Technologies
              </h2>
              <p className="text-base text-gray-700">
                We use cookies and similar technologies to:
              </p>
              <ul className="space-y-2 mt-2">
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Remember your preferences.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Track Website usage for analytics purposes.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Deliver targeted advertisements.
                </li>
              </ul>
              <p className="text-base text-gray-700 mt-2">
                You can manage cookie preferences through your browser settings.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold text-primary uppercase mb-4">
                5. Data Security
              </h2>
              <p className="text-base text-gray-700">
                We implement appropriate technical and organizational measures
                to protect your personal information from unauthorized access,
                loss, or misuse.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold text-primary uppercase mb-4">
                6. Your Rights
              </h2>
              <p className="text-base text-gray-700">
                Depending on your jurisdiction, you may have the following
                rights:
              </p>
              <ul className="space-y-2 mt-2">
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Access and update your personal information.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Request deletion of your data.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  Opt-out of marketing communications.
                </li>
                <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                  File a complaint with a data protection authority.
                </li>
              </ul>
              <p className="text-base text-gray-700 mt-2">
                To exercise these rights, please contact us at{" "}
                <a
                  href="mailto:info@shefscloud.com"
                  className="text-primary underline"
                >
                  info@shefscloud.com
                </a>
                .
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold text-primary uppercase mb-4">
                7. Third-Party Links
              </h2>
              <p className="text-base text-gray-700">
                Our Website may contain links to third-party websites. We are
                not responsible for their privacy practices, so please review
                their policies.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold text-primary uppercase mb-4">
                8. Children’s Privacy
              </h2>
              <p className="text-base text-gray-700">
                Our Website is not intended for children under the age of 13. We
                do not knowingly collect personal information from children.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold text-primary uppercase mb-4">
                9. Updates to This Policy
              </h2>
              <p className="text-base text-gray-700">
                We may update this Privacy Policy periodically. Any changes will
                be posted with a revised effective date.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold text-primary uppercase mb-4">
                10. Contact Us
              </h2>
              <p className="text-base text-gray-700">
                If you have any questions about this Privacy Policy, please
                contact us:
              </p>
              <p className="text-base text-gray-700 mt-2">
                Email:{" "}
                <a
                  href="mailto:info@shefscloud.com"
                  className="text-primary underline"
                >
                  info@shefscloud.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default PrivacyPolicy;
