import React, { useState, useRef, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const MapWithConditionalRendering = ({ coordinates, onDragEnd, showMap }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef(null); // Holds the Google Map instance
  const markerRef = useRef(null); // Holds the Marker instance

  // Load Google Maps script dynamically
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, // Replace with your API key
    libraries: ["places"], // Add other libraries if needed
  });

  useEffect(() => {
    if (showMap && !mapRef.current) {
      setMapLoaded(false); // Reset loading state when showing the map for the first time
    }
  }, [showMap]);

  const handleMapLoad = (map) => {
    mapRef.current = map; // Persist map instance
    setTimeout(() => setMapLoaded(true), 500);
  };

  const handleMarkerLoad = (marker) => {
    markerRef.current = marker; // Persist marker instance
  };

  if (loadError) {
    return <div>Error loading map</div>;
  }

  if (!isLoaded) {
    return <div>Loading Map...</div>; // Show loading message until script is loaded
  }
  return (
    <div className="col-span-2 mt-1">
      {showMap ? (
        <>
          {/* {!mapLoaded && <div>Loading Map...</div>} */}
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={coordinates}
            zoom={13}
            onLoad={handleMapLoad}
          >
            {mapLoaded && (
              <Marker
                position={coordinates}
                draggable={true}
                onLoad={handleMarkerLoad}
                onDragEnd={onDragEnd}
              />
            )}
          </GoogleMap>
        </>
      ) : (
        <div>Map is hidden</div>
      )}
    </div>
  );
};

export default MapWithConditionalRendering;
