import React, { useState } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

export const Faqs = () => {
  const pageBnrImg = {
    position: "relative",
    backgroundImage: `url('./media/frontend/img/about/blog/1920x700/banner-2.jpg')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
  const accordionData = [
    {
      title: "How do I place an order for food on Shefs Cloud?",
      content:
        "Select your favorite meals, add them to the cart, and checkout once you have entered your location. Place your order online and wait for your meal to arrive.",
    },
    {
      title: "On Shefs Cloud, what kind of food can I order? ",
      content:
        "There are lots of homemade dishes to choose from, including traditional meals, snacks, baked goods, and desserts. Whether you're craving biryani, pasta, desi curries, or freshly baked goods, Shefs Cloud's got it!",
    },
    {
      title: "Is Shefs Cloud a grocery delivery service? ",
      content:
        "No . Its a portal for Home chefs where chefs can register and get orders for delivery.",
      // "Yes! Shefs Cloud delivers meals, fresh produce, items, and essentials directly from local shops near you.",
    },
    {
      title: "Is Shefs Cloud available 24/7? ",
      content:
        "Shefs Cloud operates with flexible delivery hours based on home chefs availability in your area. However, you can check active home chefs by entering your location.",
    },
    {
      title: "Can I pay cash for Shefs Cloud orders?",
      content: "Yes, you can pay cash on delivery for your Shefs Cloud orders.",
    },

    {
      title: "Does Shefs Cloud offer online payment options? ",
      content:
        "Absolutely! You can securely pay online using your credit/debit card or digital wallets.",
    },

    {
      title: "Can I order food for someone else? ",
      content:
        "Yes! You can place an order for friends, family, or colleagues. Just update their name and delivery address at checkout.",
    },

    {
      title: "How much does Shefs Cloud charge for delivery?",
      content:
        "Delivery charges depend on your location and the home chef you are ordering from. You can see the delivery fee while finalizing your order.",
    },

    {
      title: "Is there a minimum order value? ",
      content: (
        <div>
          <p>
            Some home chefs may have a minimum order requirement, which will be
            displayed during the ordering process.
          </p>
        </div>
      ),
    },
    // {
    //   title: "What is the difference between Delivery? ",
    //   content: (
    //     <div>
    //       <p>
    //         Delivery: Your order will be delivered to your specified address.{" "}
    //       </p>
    //     </div>
    //   ),
    // },
    {
      title: "Enjoy Fresh, Homemade Meals with Shefs Cloud",
      content: (
        <div>
          <p>
            Say a big no to unhealthy fast food and expensive restaurants. Shefs
            Cloud delivers nutritious, home-cooked meals and frozen food
            delivered quickly and affordably. Place your order now and savour
            the taste of home!{" "}
          </p>
        </div>
      ),
    },
  ];
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <>
      <Header />
      <section>
        <div className="pageBanr py-20" style={pageBnrImg}>
          <div className="container mx-auto relative z-[1] text-center">
            <h2 className="font-semibold text-3xl text-white uppercase text-secondary tracking-wider tracking-widest">
              Faqs
            </h2>
            <ul className="inline-block text-white mb-0">
              <li className="inline-block text-lg">
                <Link to="/" className="!text-white hover:!text-primary">
                  Home
                </Link>
              </li>
              <li className="inline-block px-3">/</li>
              <li className="inline-block text-lg font-semibold text-primary">
                Faqs
              </li>
            </ul>
          </div>
        </div>
        <div className="container mx-auto py-12">
          <div className="lg:w-2/3 mx-auto">
            {accordionData.map((section, index) => (
              <div
                key={index}
                onClick={() => handleToggle(index)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="border-b text-lg font-semibold rounded-lg px-4 py-2 mb-0">
                  {section.title}
                </h3>
                {expandedIndex === index && (
                  <p className="border-b border-r border-l rounded-lg p-4">
                    {section.content}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default Faqs;
