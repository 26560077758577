import React, { useState } from "react";
import { handleUserSignUp } from "../../auth/Auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";

const HeroBanner = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    phone: "",
    first_name: "",
    last_name: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // Input field - OnChnage
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;

    // check initial error
    if (name === "last_name") {
      if (credentials.phone === "") {
        setError("Mobile number must be in the format 03XXXXXXXXX");
      }
    }

    if (name === "phone") {
      const phoneRegex = /^03\d{9}$/;
      if (!phoneRegex.test(value)) {
        setError("Mobile number must be in the format 03XXXXXXXXX");
      } else {
        setError("");
      }
      // Update the credentials with the formatted phone number
      setCredentials({
        ...credentials,
        [name]: value,
      });
    } else if (name === "email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
      setCredentials({
        ...credentials,
        [name]: value,
      });
    } else {
      setCredentials({
        ...credentials,
        [name]: value,
      });
    }
  };

  // Form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailError && !error) {
      setIsLoading(true);
      try {
        e.preventDefault();
        const res = await handleUserSignUp(credentials);
        // if(res.email) {

        toast.success(
          typeof res.message === "string"
            ? res.message
            : res.message.original.message || "Register Successfully"
        );
        setIsLoading(false);
        if (typeof res.message !== "string") {
          navigate("/login");
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="heroBanner">
        <div className="container mx-auto">
          <div className="md:py-20 py-12 lg:px-0 px-4">
            <div className="grid grid-cols-12 gap-4">
              <div className="lg:col-span-4 col-span-12 lg:order-1 order-2">
                <div className="content-wrapper bg-white rounded-lg px-6 py-8">
                  <div className="content-box text-center">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-4">
                        <input
                          onChange={handleChange}
                          value={credentials.email}
                          type="email"
                          name="email"
                          className=""
                          placeholder="john@example.com"
                          required
                        />
                        {/* Error warning */}
                        {emailError && (
                          <div className="bg-[#ff1c1c31] mt-1 text-red-900 py-1 px-1 text-[12px] font-semibold">
                            {emailError}
                          </div>
                        )}
                      </div>
                      <div className="form-group mb-4">
                        <input
                          onChange={handleChange}
                          value={credentials.first_name}
                          type="text"
                          name="first_name"
                          className=""
                          placeholder="First Name"
                          required
                        />
                      </div>
                      <div className="form-group mb-4">
                        <input
                          onChange={handleChange}
                          value={credentials.last_name}
                          type="text"
                          name="last_name"
                          className=""
                          placeholder="Last Name"
                          required
                        />
                      </div>
                      <div className="form-group mb-4">
                        <InputMask
                          mask="03999999999" // Fixed +92, and allow the user to enter 10 digits after it
                          value={credentials.phone}
                          onChange={handleChange}
                          className="your-input-class" // Your custom class here
                          placeholder="032xxxxxxxxx"
                          required
                          maskChar={"X"}
                          alwaysShowMask={true}
                        >
                          {(inputProps) => (
                            <input
                              {...inputProps}
                              type="text"
                              name="phone"
                              required
                            />
                          )}
                        </InputMask>
                        {/* Error warning */}
                        {error && (
                          <div className="bg-[#ff1c1c31] mt-1 text-red-900 py-1 px-1 text-[12px] font-semibold">
                            {error}
                          </div>
                        )}
                      </div>
                      <div className="form-group mb-8">
                        <input
                          onChange={handleChange}
                          value={credentials.password}
                          type="password"
                          name="password"
                          className=""
                          placeholder="Password"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <button
                          disabled={error || emailError || isLoading}
                          className="rounded-md py-2 px-4 text-base font-semibold whitespace-nowrap bg-primary text-white hover:text-green-400 uppercase tracking-wider disabled:cursor-not-allowed disabled:opacity-50"
                        >
                          {isLoading ? (
                            <div className="flex items-center">
                              <svg
                                className="w-5 h-5 mr-2 animate-spin text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                                ></path>
                              </svg>
                              Loading...
                            </div>
                          ) : (
                            "Get Started"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-8 col-span-12 my-auto lg:order-2 order-1">
                <div className="lg:pl-10 lg:text-start text-center">
                  <h1 className="font-semibold sm:text-5xl text-4xl md:leading-tight leading-snug uppercase text-white tracking-widest">
                    Finest Culinary Delights
                  </h1>
                  <div className="w-[80px] h-[2px] bg-primary lg:mx-0 mx-auto my-6"></div>
                  <h3 className="alexBrush text-3xl text-headGray mb-0">
                    Start your experience
                  </h3>
                  <div className="lg:w-1/2 w-full lg:mx-0 mx-auto">
                    <p className="text-base text-white mb-4 mt-5">
                      Explore quality, authentic meals - delivered directly from
                      trusted local chefs to your doorstep.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroBanner;
