import React from "react";
import { Link } from "react-router-dom";
const MeetChef = () => {
  return (
    <>
      <div className="meetChef pb-3 bg-primaryLight">
        <div className="">
          <div className="container mx-auto lg:px-2 px-4">
            <div className="grid grid-cols-12">
              <div className="lg:col-span-6 col-span-12">
                <div className="lg:py-5 lg:pr-12 lg:text-start text-center">
                  <h1 className="font-semibold text-3xl uppercase text-secondary tracking-wider tracking-widest">
                    Meet the shefs
                  </h1>
                  <div className="w-[60px] h-[2px] bg-primary my-4 lg:mx-0 mx-auto"></div>
                  <h3 className="alexBrush text-3xl text-headGray">
                    Our Experts
                  </h3>
                  <div className="mt-4 mb-4">
                    <div className="lg:w-4/5 w-full">
                      <p className="mb-4 text-lg text-headGray">
                        Shefs Cloud works with a team of talented and passionate
                        home chefs who combine taste, health, and quality to
                        create mouthwatering meals for you. Every chef is
                        carefully selected to ensure they meet our high
                        standards for hygiene, skill, and dedication. From
                        traditional home recipes to innovative dishes, our
                        experts bring their culinary expertise to your plate.
                      </p>
                      <p className="mb-4 text-lg text-headGray">
                        In addition to logistics specialists, nutritionists, and
                        customer service professionals, the Shefs Cloud team
                        works hard to make your experience simple and
                        satisfying. No matter what you order, our experts ensure
                        that every order is fresh, affordable, and delivered
                        right on time.
                      </p>
                    </div>
                  </div>
                  <Link
                    to="/all-chef"
                    className="rounded-md py-2 px-6 text-base tracking-wider uppercase font-semibold whitespace-nowrap bg-primary pb-3 !text-white border border-transparent hover:border-primaryGreen hover:!text-green-400 "
                  >
                    All Chefs
                  </Link>
                  {/* <Link to='/all-chef' className='rounded-md py-2 px-6 text-base tracking-wider uppercase font-semibold whitespace-nowrap bg-primary py-3 !text-white border border-transparent hover:border-primaryGreen hover:!text-green-400 '>Read More</Link> */}
                </div>
              </div>
              {/* <div className='lg:col-span-6 col-span-12'>
                                <div className='grid lg:grid-cols-3 md:grid-cols-4 grid-cols-2 gap-5 lg:mt-0 mt-8'>
                                    <div className='border border-primary border-dashed p-2 rounded-lg'>
                                        <img src='./media/frontend/img/banner/chef-1.webp' className='rounded-lg w-full h-[140px] object-top object-cover shadow-xl' alt='Chef' />
                                    </div>
                                    <div className='border border-primary border-dashed p-2 rounded-lg'>
                                        <img src='./media/frontend/img/banner/chef-2.webp' className='rounded-lg w-full h-[140px] object-top object-cover shadow-xl' alt='Chef' />
                                    </div>
                                    <div className='border border-primary border-dashed p-2 rounded-lg'>
                                        <img src='./media/frontend/img/banner/chef-3.webp' className='rounded-lg w-full h-[140px] object-top object-cover shadow-xl' alt='Chef' />
                                    </div>
                                    <div className='border border-primary border-dashed p-2 rounded-lg'>
                                        <img src='./media/frontend/img/banner/chef-4.webp' className='rounded-lg w-full h-[140px] object-top object-cover shadow-xl' alt='Chef' />
                                    </div>
                                    <div className='border border-primary border-dashed p-2 rounded-lg'>
                                        <img src='./media/frontend/img/banner/chef-5.webp' className='rounded-lg w-full h-[140px] object-top object-cover shadow-xl' alt='Chef' />
                                    </div>
                                    <div className='border border-primary border-dashed p-2 rounded-lg'>
                                        <img src='./media/frontend/img/banner/chef-6.webp' className='rounded-lg w-full h-[140px] object-top object-cover shadow-xl' alt='Chef' />
                                    </div>
                                    <div className='border border-primary border-dashed p-2 rounded-lg'>
                                        <img src='./media/frontend/img/banner/chef-7.webp' className='rounded-lg w-full h-[140px] object-top object-cover shadow-xl' alt='Chef' />
                                    </div>
                                    <div className='border border-primary border-dashed p-2 rounded-lg'>
                                        <img src='./media/frontend/img/banner/chef-8.webp' className='rounded-lg w-full h-[140px] object-top object-cover shadow-xl' alt='Chef' />
                                    </div>
                                    <div className='border border-primary border-dashed p-2 rounded-lg'>
                                        <img src='./media/frontend/img/banner/chef-4.webp' className='rounded-lg w-full h-[140px] object-top object-cover shadow-xl' alt='Chef' />
                                    </div>
                                    
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetChef;
