import React from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'

export const TermsOfServices = () => {
    const pageBnrImg = {
        position: 'relative',
        backgroundImage: `url('./media/frontend/img/about/blog/1920x700/banner-2.jpg')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      };
    return (
        <>
            <Header />
            <section>
                <div className='pageBanr py-20' style={pageBnrImg}>
                    <div className='container mx-auto relative z-[1] text-center'>
                        <h2 className="font-semibold text-3xl text-white uppercase text-secondary tracking-wider tracking-widest">
                            Terms of Services
                        </h2>
                        <ul className='inline-block text-white mb-0'>
                            <li className='inline-block text-lg'>
                                <Link to='/' className='!text-white hover:!text-primary'>Home</Link>
                            </li>
                            <li className='inline-block px-3'>/</li>
                            <li className='inline-block text-lg font-semibold text-primary'> Terms of Services</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="termsOfService section-padding">
  <div className="container mx-auto lg:px-2 px-4">
    <div className="lg:w-3/4 w-full mx-auto">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-primary uppercase mb-4">
          Terms of Service
        </h1>
        <p className="text-base text-gray-700">Effective Date: 15 January 2025</p>
        <p className="text-base text-gray-700 mt-2">
          Welcome to Shefs Cloud (the “Website”). By accessing or using our platform, you agree to these Terms of Service (“Terms”). These Terms govern your access to and use of our Website, services, and interactions between home shefs (“Shefs”) and customers (“Users”). If you do not agree, please refrain from using the Website.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          1. Overview
        </h2>
        <p className="text-base text-gray-700">
          Shefscloud.com provides a platform that connects home chefs with customers looking for personalized meal preparation services. We act as an intermediary and are not responsible for the actual preparation or delivery of food.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          2. Eligibility
        </h2>
        <ul className="space-y-2">
          <li className="pl-5 text-base text-gray-700 list-disc list-inside">
            You have the legal capacity to enter into a binding agreement.
          </li>
          <li className="pl-5 text-base text-gray-700 list-disc list-inside">
            You will provide accurate and complete registration information.
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          3. User Accounts
        </h2>
        <ul className="space-y-4">
          <li>
            <h3 className="text-lg font-semibold text-gray-900">a. Registration:</h3>
            <p className="pl-5 text-base text-gray-700">
              To access certain features, you must create an account and provide accurate details.
            </p>
          </li>
          <li>
            <h3 className="text-lg font-semibold text-gray-900">b. Account Security:</h3>
            <p className="pl-5 text-base text-gray-700">
              You are responsible for maintaining the confidentiality of your account credentials. Notify us immediately of any unauthorized use of your account.
            </p>
          </li>
          <li>
            <h3 className="text-lg font-semibold text-gray-900">c. Termination of Accounts:</h3>
            <p className="pl-5 text-base text-gray-700">
              We reserve the right to suspend or terminate your account for any violation of these Terms or fraudulent activity.
            </p>
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          4. Role of Shefscloud.com
        </h2>
        <ul className="space-y-4">
          <li>
            <h3 className="text-lg font-semibold text-gray-900">a. Intermediary Only:</h3>
            <p className="pl-5 text-base text-gray-700">
              shefscloud.com acts solely as a platform to connect Shefs and Users. We do not prepare, deliver, or supervise food services.
            </p>
          </li>
          <li>
            <h3 className="text-lg font-semibold text-gray-900">b. Shef Responsibilities:</h3>
            <ul className="space-y-2">
              <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                Comply with local food safety regulations.
              </li>
              <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                Deliver services as advertised on the platform.
              </li>
              <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                Maintain a professional standard of hygiene and food quality.
              </li>
            </ul>
          </li>
          <li>
            <h3 className="text-lg font-semibold text-gray-900">c. User Responsibilities:</h3>
            <ul className="space-y-2">
              <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                Provide accurate information for orders (e.g., delivery address, dietary preferences).
              </li>
              <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                Communicate respectfully with Shefs.
              </li>
              <li className="pl-5 text-base text-gray-700 list-disc list-inside">
                Pay for services in accordance with the platform’s policies.
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          5. Payments and Fees
        </h2>
        <ul className="space-y-4">
          <li>
            <h3 className="text-lg font-semibold text-gray-900">a. Payment Processing:</h3>
            <p className="pl-5 text-base text-gray-700">
              Currently shefscloud only accepts cash-on-delivery, and online payment is not available.
            </p>
          </li>
          <li>
            <h3 className="text-lg font-semibold text-gray-900">b. Platform Fees:</h3>
            <p className="pl-5 text-base text-gray-700">
              shefscloud.com may charge a service fee for using the platform from Shefs only. Any applicable fees will be disclosed at checkout.
            </p>
          </li>
          <li>
            <h3 className="text-lg font-semibold text-gray-900">c. Refunds and Disputes:</h3>
            <p className="pl-5 text-base text-gray-700">
              Refund policies vary depending on the Shef. In case of disputes, contact us at info@shefscloud.com or WhatsApp at +92 320 2024035, and we will mediate to resolve the issue.
            </p>
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          6. Cancellations and Refunds
        </h2>
        <ul className="space-y-4">
          <li>
            <h3 className="text-lg font-semibold text-gray-900">a. Chef Cancellations:</h3>
            <p className="pl-5 text-base text-gray-700">
              If a Shef cancels an order, Users are entitled to a full refund if any advance payment was made by the customer.
            </p>
          </li>
          <li>
            <h3 className="text-lg font-semibold text-gray-900">b. User Cancellations:</h3>
            <p className="pl-5 text-base text-gray-700">
              Order cancellation is allowed for some time after placing the order. After this time, cancellation by the User will impact the User's profile registration if the Shef reports the issue.
            </p>
          </li>
          <li>
            <h3 className="text-lg font-semibold text-gray-900">c. Force Majeure:</h3>
            <p className="pl-5 text-base text-gray-700">
              shefscloud.com is not liable for cancellations or delays caused by events beyond our control, such as natural disasters or emergencies.
            </p>
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          7. Prohibited Activities
        </h2>
        <p className="text-base text-gray-700">
          You agree not to:
        </p>
        <ul className="space-y-2 mt-2">
          <li className="pl-5 text-base text-gray-700 list-disc list-inside">
            Use the platform for unlawful or fraudulent purposes.
          </li>
          <li className="pl-5 text-base text-gray-700 list-disc list-inside">
            Post false or misleading information.
          </li>
          <li className="pl-5 text-base text-gray-700 list-disc list-inside">
            Harass, abuse, or harm other Users or Chefs.
          </li>
          <li className="pl-5 text-base text-gray-700 list-disc list-inside">
            Violate any intellectual property or proprietary rights.
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          8. Content and Intellectual Property
        </h2>
        <ul className="space-y-4">
          <li>
            <h3 className="text-lg font-semibold text-gray-900">a. User-Generated Content:</h3>
            <p className="pl-5 text-base text-gray-700">
              You may upload reviews, comments, or other content to the platform. By doing so, you grant shefscloud.com a non-exclusive, royalty-free license to use, modify, or distribute your content.
            </p>
          </li>
          <li>
            <h3 className="text-lg font-semibold text-gray-900">b. Ownership:</h3>
            <p className="pl-5 text-base text-gray-700">
              All materials on the Website, including logos, text, and images, are owned by shefscloud.com or its licensors. Unauthorized use is prohibited.
            </p>
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          9. Limitation of Liability
        </h2>
        <p className="text-base text-gray-700">
          Shefscloud.com is not liable for:
        </p>
        <ul className="space-y-2 mt-2">
          <li className="pl-5 text-base text-gray-700 list-disc list-inside">
            Food quality, preparation, or safety issues.
          </li>
          <li className="pl-5 text-base text-gray-700 list-disc list-inside">
            Losses arising from disputes between Users and Shefs.
          </li>
          <li className="pl-5 text-base text-gray-700 list-disc list-inside">
            Indirect, incidental, or consequential damages resulting from the use of the platform.
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          10. Privacy
        </h2>
        <p className="text-base text-gray-700">
          Your use of the Website is governed by our Privacy Policy. Please review it to understand how we collect, use, and protect your information.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          11. Governing Law
        </h2>
        <p className="text-base text-gray-700">
          These Terms are governed by the laws of the country. Any disputes shall be resolved in the courts of the country.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          12. Changes to These Terms
        </h2>
        <p className="text-base text-gray-700">
          We may update these Terms from time to time. Continued use of the Website after changes are posted constitutes your acceptance of the revised Terms.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-primary uppercase mb-4">
          13. Contact Us
        </h2>
        <p className="text-base text-gray-700">
          For questions or concerns regarding these Terms, contact us at:
        </p>
        <p className="text-base text-gray-700 mt-2">
          Email: <a href="mailto:info@shefscloud.com" className="text-primary underline">info@shefscloud.com</a>
        </p>
        <p className="text-base text-gray-700 mt-2">
          Phone: <a href="tel:+923202024035" className="text-primary underline">+92 320 2024035</a>
        </p>
      </div>
    </div>
  </div>
</section>

            <Footer/>
        </>
    )
}
export default TermsOfServices