import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";

export const HomeFoodDelivery = () => {
  const pageBnrImg = {
    position: "relative",
    backgroundImage: `url('./media/frontend/img/about/blog/1920x700/banner-3.jpg')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
  const { userInfo } = useSelector((state) => state.user);
  return (
    <>
      <Header />
      <section className="lg:px-2 px-4">
        <div className="pageBanr py-20" style={pageBnrImg}>
          <div className="container mx-auto relative z-[1] text-center">
            <div className="text-center lg:px-0 px-4">
              <h1 className="font-semibold sm:text-5xl text-4xl md:leading-none leading-snug uppercase text-white tracking-wider tracking-widest">
                Where Does Shef Deliver?
              </h1>
              <div className="w-[80px] h-[2px] bg-primary mx-auto my-6"></div>
              <h3 className="alexBrush text-3xl text-headGray">
                Food delivery
              </h3>
              <div className="lg:w-2/5 sm:w-1/2 w-full mx-auto">
                <div className="bnrSearchBar flex gap-x-2">
                  <input
                    type="search"
                    className="py-2 h-[45px] border border-headGray text-base focus:border-primary"
                    placeholder="Enter your ZIP code"
                  />
                  <button className="rounded-md py-2 px-4 text-base font-semibold whitespace-nowrap bg-primary text-white hover:text-green-400">
                    Find Food
                  </button>
                </div>
                {!userInfo && (
                  <p className="text-base text-white mb-4 mt-4 lg:px-16">
                    Already have an account?{" "}
                    <Link to="/login" className="hover:underline font-semibold">
                      Log in
                    </Link>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container mx-auto py-16">
            <div className="text-center mb-8">
              <h2 className="font-semibold text-3xl uppercase tracking-wider tracking-widest">
                Where Shef Cooks & Delivers Homemade Food!
              </h2>
              <div className="w-[60px] h-[2px] bg-primary my-4 mx-auto"> </div>
            </div>
            {/* <div className="lg:w-2/3 mx-auto text-center border rounded-lg bg-grayBg p-5">
              <p className="text-base mb-2  italic">
                "Food for us comes from our relatives, whether they have wings
                or fins or roots. That is how we consider food. Food has a
                culture. It has a history. It has a story. It has
                relationships."
              </p>
              <p className="text-base mb-0 font-semibold">- Winona LaDuke</p>
            </div> */}
            <div className="mt-8">
              <p className="text-lg mb-4 text-center">
                You can get delicious homemade food delivered right to your door
                with Shefs Cloud's Home Food Delivery services. Bringing together
                passionate home chefs and food lovers, we serve high-quality,
                freshly prepared meals.{" "}
              </p>
              <p className="text-lg mb-4 text-center">
                Our mission is to bring culinary innovation to the forefront of
                technology at Shefs Cloud. We at Shefs Cloud are committed to
                empowering chefs, culinary businesses, as well as food
                enthusiasts through the provision of better home food solutions
                tailored to meet the individual needs of chefs, culinary
                businesses, and food enthusiasts.
              </p>
              <p className="text-lg mb-4 text-center">
                There is no doubt that seamless, efficient, and intelligent
                digital solutions will play a critical role in the future of the
                food industry. We have designed our platform in such a way that
                it simplifies operations, increases productivity, and enables
                businesses to focus on what truly matters: creating exceptional
                culinary experiences for their guests.
              </p>
              <p className="text-lg mb-4 text-center">
                For Home Chefs Shefs Cloud empowers passionate home chefs to share
                their culinary creations with a wider audience. Our platform
                makes it easy to:
              </p>
              <p className="text-lg mb-4 text-center">
                * Showcase your talent and unique recipes. <br /> * Reach food
                enthusiasts looking for homemade goodness. <br />* Manage orders
                efficiently with our intuitive tools.
              </p>
            </div>
            <div className="border-t mt-8 pt-8">
              <div className="mb-8">
                <h2 className="text-xl font-bold mb-5 uppercase">Top Cities</h2>
                <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-3">
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Karachi
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Islamabad
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Punjab
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Balochishtan
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    KPK
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Punjab
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Karachi
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Islamabad
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Punjab
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Balochishtan
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    KPK
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Punjab
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Karachi
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Islamabad
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Punjab
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Balochishtan
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    KPK
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    Punjab
                  </h6>
                </div>
              </div>
              <div>
                <h2 className="text-xl font-bold mb-5 uppercase">
                  Top Zip Codes
                </h2>
                <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-3">
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00001
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00002
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00003
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00004
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00005
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00006
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00001
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00002
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00003
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00004
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00005
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00006
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00001
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00002
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00003
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00004
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00005
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00006
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00001
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00002
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00003
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00004
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00005
                  </h6>
                  <h6 className="text-center border rounded-lg bg-grayBg p-3 text-lg font-semibold mb-0">
                    00006
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-grayBg py-16">
          <div className="container mx-auto">
            <div className="text-center mb-8">
              <h2 className="font-semibold text-3xl uppercase tracking-wider">
                Why Choose Shefs Cloud Home Food Delivery?
              </h2>
              <div className="w-[60px] h-[2px] bg-primary my-4 mx-auto"> </div>
            </div>
            <div className="grid lg:grid-cols-1 gap-4">
              <div className="bg-white rounded-lg px-5 py-8 h-full">
                <h6 className="mb-4 mt-2 text-xl font-semibold uppercase text-center md:px-4 px-0 leading-5">
                  Authentic Homemade Meals
                </h6>
                <p className="text-lg mb-0 text-center">
                  Enjoy meals crafted with love and care by talented home chefs.
                </p>
              </div>
              <div className="bg-white rounded-lg px-5 py-8 h-full">
                <h6 className="mb-4 mt-2 text-xl font-semibold uppercase text-center md:px-4 px-0 leading-5">
                  Variety and Personalization
                </h6>
                <p className="text-lg mb-0 text-center">
                  Discover a range of cuisines and meal options tailored to your
                  preferences.
                </p>
              </div>
              <div className="bg-white rounded-lg px-5 py-8 h-full">
                <h6 className="mb-4 mt-2 text-xl font-semibold uppercase text-center md:px-4 px-0 leading-tight">
                  Fresh and Hygienic{" "}
                </h6>
                <p className="text-lg mb-0 text-center">
                  All meals are prepared with fresh ingredients and adhere to
                  the highest hygiene standards.
                </p>
              </div>
              <div className="bg-white rounded-lg px-5 py-8 h-full">
                <h6 className="mb-4 mt-2 text-xl font-semibold uppercase text-center md:px-4 px-0 leading-tight">
                  Convenient Delivery
                </h6>
                <p className="text-lg mb-0 text-center">
                  Get your favorite meals delivered quickly and reliably to your
                  doorstep.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container mx-auto py-16">
            <div className="text-center mb-8">
              <h2 className="font-semibold text-3xl uppercase tracking-wider tracking-widest">
                Our Mission
              </h2>
              <div className="w-[60px] h-[2px] bg-primary my-4 mx-auto"> </div>
            </div>

            <div className="mt-8">
              <p className="text-lg mb-4 text-center">
                Our mission is to bring people together through food, whether
                you're craving a nostalgic home-cooked meal, exploring new
                flavors, or just looking for healthier alternatives.
              </p>
              <p className="text-lg mb-4 text-center">
                You can get delicious, homemade food right now. Experience the
                joy of home-cooked meals anytime, anywhere!
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default HomeFoodDelivery;
